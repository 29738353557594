import React from 'react';

const ModalProviderProps = {
  children: undefined,
};

const ModalContext = React.createContext({
  show: ({ title, content }) => {},
});

export const useModal = () => {
  return React.useContext(ModalContext);
};

const Modal = ({ modal: propModal, onClose }) => {
  const [modal, setModal] = React.useState();
  const [inTransition, setInTransition] = React.useState();
  React.useEffect(() => {
    if (propModal) {
      setInTransition(true);
    } else {
      setInTransition(true);
      const t = setTimeout(() => {
        setInTransition(false);
        clearTimeout(t);
      }, 500);
    }
  }, [propModal]);

  React.useEffect(() => {
    if (propModal) {
      setModal(propModal);
    } else {
      setModal();
    }
  }, [propModal, inTransition]);
  // if (!modal) return null;
  // if (!propModal && !modal && !inTransition) return null;

  if (!propModal && !inTransition) return null;
  return (
    <div
      className={`modal-dimming${modal ? ' open' : inTransition ? ' close' : ''}`}
      onClick={(e) => e?.preventDefault?.()}
    >
      <div className={['modal', modal?.className, modal?.isFull && 'full'].filter(Boolean).join(' ')}>
        <button className="close" type="button" onClick={onClose} />
        {!!modal?.title && <div className="title">{modal?.title}</div>}
        {!!modal?.content && <div className="body">{modal?.content}</div>}
      </div>
    </div>
  );
};

export default function ModalProvider({ children } = ModalProviderProps) {
  const [modal, setModal] = React.useState();

  return (
    <ModalContext.Provider
      value={{
        show: (modal) => {
          setModal(modal);
        },
      }}
    >
      <Modal modal={modal} onClose={() => setModal()} />
      {children}
    </ModalContext.Provider>
  );
}
