import React from 'react';
import { useComment } from '../contexts/CommentContext';
import { useKakaoLogin } from '../contexts/KakaoLogin';
import { Comment } from './Comments';
import KakaoLogin from './KakaoLogin';

const CommentInputProps = {
  value: undefined,
  placeholder: '',
  placeholderWho: '',
  onEnter: () => {},
  shouldClearAfterReturn: true,
};
export default function CommentInput(props = CommentInputProps) {
  const { placeholder, placeholderWho, shouldClearAfterReturn } = Object.assign({}, CommentInputProps, props);
  const [message, setMessage] = React.useState('');

  const valueRef = React.useRef();
  const { info, isLogin } = useKakaoLogin();
  const { onEnter, replyTarget, inputShow } = useComment();

  const handleChangeValue = (e) => {
    setMessage(e?.target?.value);
  };
  const handleSend = () => {
    onEnter?.({
      message,
      who: {
        id: info.id,
        nickname: info.properties.nickname,
        thumbnail_image: info.properties.thumbnail_image,
      },
    });
    if (shouldClearAfterReturn) {
      setMessage('');
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      // handleSend();
    }
  };

  return (
    <div className={`float-input ${inputShow ? 'show' : 'hide'}`}>
      {!isLogin ? (
        <KakaoLogin />
      ) : (
        <div className="comment-input-container">
          <img className="profile" src={info?.properties?.thumbnail_image} alt="" />
          <div className="comment-input-box">
            {!!replyTarget && <span>@{replyTarget?.who?.nickname}</span>}
            <input
              className="comment-input"
              type="text"
              value={message}
              onChange={handleChangeValue}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
            />
          </div>
          <button className="send" type="button" onClick={handleSend}>
            입력
          </button>
        </div>
      )}
    </div>
  );
}
