import React from 'react';
import sr from 'scrollreveal';
const ScrollRevealProps = {
  children: undefined,
  options: { reset: false, duration: 500 },
  onBeforeReveal: () => {},
  onAfterReveal: () => {},
};
function ScrollReveal(props = ScrollRevealProps, ref) {
  const {
    children,
    options = { reset: false, duration: 500 },
    onBeforeReveal,
    onAfterReveal,
    ...etcProps
  } = Object.assign({}, ScrollRevealProps, props);
  const divRef = React.useRef(ref);

  React.useEffect(() => {
    if (divRef.current && typeof window !== 'undefined') {
      const scrollReveal = require('scrollreveal').default;
      sr().reveal(divRef.current, {
        ...options,
        beforeReveal: typeof onBeforeReveal === 'function' ? onBeforeReveal : undefined,
        afterReveal: typeof onAfterReveal === 'function' ? onAfterReveal : undefined,
      });
    } else {
      // console.log('what the...', props, divRef, window);
    }
  }, []);

  return React.createElement(children?.type, {
    ...etcProps,
    ...children?.props,
    ref: divRef,
  });
}
export default React.forwardRef(ScrollReveal);
