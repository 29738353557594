import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/react';
import 'swiper/css';
import useScreen from '../hooks/useScreen';
import { createArray } from '../commons/utils';
import firebaseApp from '../commons/firebase';
import * as Storage from 'firebase/storage';
import { useModal } from '../contexts/ModalContext';
import ZoomImage from './ZoomImage';
import Loading from './Loading';

const Photos = [
  '91a8ee1544cd29be07845579bba1c6d3',
  'c23c3e62dfd1c01ff74ec320bebce845',
  'f462d30439904df970893653ce55aab0',
  '771fd1cea5255f4fc496c5bb562a8f47',
  '4cb7355c6d07a89097551025446ccd49',
  'c54d6fe11ca5cf5a09ec199d021a3120',
  'c97a1918b347341d275ea7dd2eeec100',
  'c33e967442f2fe0ae3fa3d08358b14a6',
  '1065cb476031b251c1bd0c517617b0d7',
  'd411123a07bb4d402e8245138676199c',
  '228ce5e77db29df300fcff5b2cf19d6d',
  'd55a24f3f26f6c4e59745275abb2c07c',
  'ac2af8cad1f97dcf979326e7154f0d22',
  'c4bc4d143b4bcaa5f1279c985e098caa',
  'ffd9cfd9fe070c4273ef19028def967f',
  '9a80e6422d005826231dbcddd8f9598e',
  'da5268f4fdbc22c4298a8099d5b79d9c',
  '99cb1ab95f3c331daca10f064870d352',
  'cdb1d288e3b0d9428c503a35cafba7c0',
  '706cf33b2212c5afa21a4976e881ba90',
  'c15db2dd024dc0e0f5b79a433a03c1ef',
  '634ececc3ff23c6148c1f22c8b940a82',
  'febe2bab19ee8d080febf0892ababfba',
  'a641ffe64fbc4644939fa331925a8865',
  '8983c57bebf2be5c9434dc01beea2287',
  '8319d6d9559c30840d30e3e59d5b446d',
  'a8a719f5d2b38f54bb85632f8fee9254',
  'c36499a02595adc9701f091d70e17fc6',
  '35fc9142cce47e75e394dd2c32838098',
  '8c4ba88bb76ff1117c30894dd1c06662',
  'e180c3c882b013765ceafa022bfe18cc',
  '60e03fd545f1ab99a512afaf6bc16888',
  '91ce0048d005b4bcf49c223527d2d8ee',
  'a7e71718e22bd3f0f6eb04ff30656158',
];

function PhotoGrid(props, ref) {
  const swiper = React.useRef({ instance: null, timeoutRef: null }).current;
  const [activeIndex, setActiveIndex] = React.useState(1);
  React.useEffect(() => {
    // if (!list) return;
    swiper.timeoutRef = setInterval(() => {
      swiper.instance.slideTo((swiper.instance.activeIndex % Photos.length) + 1);
    }, 5000);
    return () => {
      if (swiper.timeoutRef) {
        clearInterval(swiper.timeoutRef);
        swiper.timeoutRef = null;
      }
    };
  }, []);
  const { width, height } = useScreen();

  const modal = useModal();
  const handlePhotoFull = (url) => {
    return;
    modal.show({
      isFull: true,
      content: (
        <div style={{ height: 500 }}>
          <ZoomImage url={url} />
        </div>
      ),
    });
  };

  return (
    <div ref={ref}>
      <Swiper
        onSwiper={(instance) => {
          console.log('swiper', instance);
          swiper.instance = instance;
          swiper.instance?.autoPlayStart?.();
          swiper.instance?.begin?.();
        }}
        width={width}
        height={width}
        loop={true}
        onActiveIndexChange={(e) => {
          setActiveIndex(e.activeIndex);
        }}
        onChange={(e) => {
          console.log(e);
        }}
      >
        {Photos.map((id, i) => {
          return (
            <SwiperSlide
              key={id}
              style={{ width, height: width }}
              onClick={() => {
                // handlePhotoFull(url);
              }}
            >
              <img
                src={`https://mcard1.bojagicard.com/thm/?reqfor=view&w=1920&q=70&src=/ecard/photo/m/mydog21/${id}.jpg`}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                alt={`${i}`}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* https://mcard1.bojagicard.com/thm/?w=200&h=200&zc=t&q=60&src=/ecard/photo/m/mydog21/91a8ee1544cd29be07845579bba1c6d3.jpg */}
      {/* https://mcard1.bojagicard.com/thm/?reqfor=view&w=1280&q=60&src=/ecard/photo/m/mydog21/91a8ee1544cd29be07845579bba1c6d3.jpg */}
      {/* <img src="https://mcard1.bojagicard.com/thm/?reqfor=view&w=1280&q=60&src=/ecard/photo/m/mydog21/1c834275035ab863136a3c1b474d35b1.jpg" /> */}
      <div className="photo-grid">
        {Photos.map((id, i) => {
          return (
            <div
              className={`cell ${activeIndex - 1 === i ? 'active' : ''}`}
              key={i}
              onClick={() => {
                console.log(swiper.instance);
                swiper.instance.slideTo(i + 1);
                if (swiper.timeoutRef) {
                  clearInterval(swiper.timeoutRef);
                  swiper.timeoutRef = null;
                }
                // setActiveIndex(i + 1);
              }}
            >
              <img
                key={id}
                src={`https://mcard1.bojagicard.com/thm/?w=200&h=200&zc=t&q=60&src=/ecard/photo/m/mydog21/${id}.jpg`}
                alt={`${i}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.forwardRef(PhotoGrid);
