import React from 'react';
import Lottie from 'lottie-web';

export const LottieViewProps = {
  delay: 0,
  loop: false,
  autoplay: true,
  renderer: 'svg',
  animationData: undefined,
  speed: 1,
  className: '',
};

export default function LottieView(props = LottieViewProps) {
  const { delay, loop, autoplay, animationData, speed, className, renderer } = Object.assign(
    {},
    LottieViewProps,
    props,
  );
  const ref = React.useRef();
  React.useEffect(() => {
    const animation = Lottie.loadAnimation({
      delay,
      container: ref.current,
      renderer,
      loop,
      autoplay,
      animationData,
    });
    animation.setSpeed(speed);
  }, []);
  return <div className={className} ref={ref} />;
}
