import TimeAgo from 'javascript-time-ago';
import ko from 'javascript-time-ago/locale/ko';
import moment from 'moment';

export const PhoneFormatRegExp = /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/;
export const NotANumberRegExp = /\D/g;

export const isFunc = (f) => typeof f === 'function';
export const clamp = (min, v, max) => Math.max(min, Math.min(v, max));

export const delay = async (duration) =>
  new Promise((r) => {
    const t = setTimeout(() => {
      r();
      clearTimeout(t);
    }, duration);
  });

export const chunk = (array, splitCount) => {
  if (!Array.isArray(array)) return;
  const dumpArray = [...array];
  return [...Array(Math.ceil(array.length / splitCount))].map(() => {
    return dumpArray.splice(0, splitCount);
  });
};

export const phoneNumberReplaceHyphen = (phoneNumber = '') => {
  return phoneNumber.replace(NotANumberRegExp, '');
};
export const phoneNumberFormat = (phoneNumber = '') => {
  return phoneNumber?.replace(NotANumberRegExp, '')?.replace(PhoneFormatRegExp, '$1-$2-$3');
};
export const createArray = (count, mapper) => {
  if (mapper && typeof mapper === 'function') {
    return new Array(count).fill(undefined).map((_, i, a) => mapper(i, a));
  } else {
    return new Array(count).fill(mapper);
  }
};
export const arrayUnique = (array, equalFunction) => {
  if (!Array.isArray(array) || array.length === 0) return array;
  if (isFunc(equalFunction)) {
    return array.filter((e, i, a) => a.findIndex((o) => equalFunction(e, o)) === i);
  }
  return array.filter((e, i, a) => a.findIndex((o) => e === o) === i);
};

export const versionNormalize = (version, l = 3, d = 2) => {
  if (!version) return '';
  return `${'0'.repeat(d * l)}${version
    .replace(/ /gi, '.')
    .replace(/\.+/gi, '.')
    .split('.')
    .filter((e, i) => i < l)
    .reduce((p, c, i) => {
      const s = i > 0 ? `${c}${'0'.repeat(d)}`.substr(0, d) : c;
      return p + Number(s) * Math.pow(Math.pow(10, d), l - 1 - i);
    }, 0)}`
    .slice(-d * l)
    .match(new RegExp('.{1,' + d + '}', 'g'))
    .join('.');
};

export const versionToNumber = (version, l = 3, d = 2) => {
  return versionNormalize(version, l, d)
    .split('.')
    .splice(0, l)
    .reduce((p, c, i) => p + Number(c) * Math.pow(Math.pow(10, d), l - 1 - i), 0);
};
const UnicodeAstralRange =
  /\ud83c[\udffb-\udfff](?=\ud83c[\udffb-\udfff])|(?:[^\ud800-\udfff][\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]?|[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\ud800-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
// !String.prototype.trunc &&
//   Object.defineProperty(String.prototype, 'trunc', {
//     value: function (n, e = '…') {
//       if (this.length === 0) return '';
//       let arr = this.match(UnicodeAstralRange);
//       if (!arr) return '';
//       return arr.slice(0, n).join('') + (arr.length > n ? e : '');
//     },
//     enumerable: false,
//   });

export const isEqualDay = (d1, d2) => {
  if (!d1 || !d2) return false;
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const code = (s) => s.charCodeAt(0);
// const createArray = number => [...Array(number)];
const _smallCase = createArray(code('z') - code('a') + 1, (i) => String.fromCharCode(code('a') + i));
const _largeCase = createArray(code('Z') - code('A') + 1, (i) => String.fromCharCode(code('A') + i));
const _numbers = createArray(10).map((_, i) => String(i));
const _strings = [..._smallCase, ..._largeCase, ..._numbers];
export const uniqueId = (length = 8) => {
  return _strings
    .reduce((p, _, __, a) => (p.length >= length ? p : [...p, a[Math.floor(Math.random() * a.length)]]), [])
    .join('');
};

TimeAgo.addLocale(ko);
const timeAgo = new TimeAgo('ko');

export const formatTimeAgo = (date, allowFuture = false, defaultString = '') => {
  if (!date) return defaultString;
  const _date = new Date(date);
  const _moment = moment(date);

  if (!allowFuture && _moment.diff(moment.utc(), 's') > 0) {
    return timeAgo.format(new Date(), '');
  }
  if (_moment.diff(moment.utc(), 'day') < 0) {
    return _moment.format('YYYY.MM.DD');
  }

  return timeAgo.format(_date, '');
};

export const joinArray = (array, join) => {
  return array.reduce((p, c, i, a) => (i === a.length - 1 ? [...p, c] : [...p, c, isFunc(join) ? join(i) : join]), []);
};
