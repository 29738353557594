import React from 'react';
import * as Database from 'firebase/database';

const CommentContext = React.createContext({
  inputShow: false,
  setInputShow: () => {},
  onEnter: ({ message, who }) => {},
  setReplyTarget: (comment) => {},
  replyTarget: undefined,
});
export const calcCommentData = (comments) => {
  if (!comments) return null;
  return Object.entries(comments)
    .map(([id, comment]) => ({ id, ...comment }))
    .sort(({ date: d1 }, { date: d2 }) => d2 - d1);
};
export const useComment = () => {
  return React.useContext(CommentContext);
};

export default function CommentProvider({ children }) {
  const [replyTarget, setReplyTarget] = React.useState();
  const [inputShow, setInputShow] = React.useState(false);
  const onEnter = React.useCallback(
    async (comment) => {
      const db = Database.getDatabase();
      if (replyTarget) {
        Database.query();
        const ref = Database.ref(db, `comments/${replyTarget.id}/reply`);
        const commentsSnap = await Database.get(ref);
        // console.log('replyTarget', comment, replyTarget);
        await Database.push(ref, {
          date: Date.now(),
          message: comment.message,
          who: comment.who,
        });
        setReplyTarget();
      } else {
        // const commentsSnap = await Database.get(ref);
        const ref = Database.ref(db, `comments`);
        await Database.push(ref, {
          date: Date.now(),
          message: comment.message,
          who: comment.who,
        });
      }
    },
    [replyTarget],
  );
  const value = React.useMemo(
    () => ({ inputShow, setInputShow, onEnter, setReplyTarget, replyTarget }),
    [onEnter, replyTarget, inputShow],
  );
  return <CommentContext.Provider value={value}>{children}</CommentContext.Provider>;
}
