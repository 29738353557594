import React from 'react';
const SectionProps = {
  header: undefined,
  bodyClassName: undefined,
  children: undefined,
  bodyStyle: undefined,
};
function Section(props = SectionProps, ref) {
  return (
    <div ref={ref}>
      {props.header && (
        <div className="section-header">
          {typeof props.header === 'string' ? <span>{props.header}</span> : props.header}
        </div>
      )}
      <div className={`section-body ${props.bodyClassName}`} style={props.bodyStyle}>
        {props.children}
      </div>
    </div>
  );
}

export default React.forwardRef(Section);
