import React from 'react';
import { useModal } from '../contexts/ModalContext';

export const TelModal = ({ who }) => {
  return (
    <div className="tel-modal" style={{ paddingRight: 20 }}>
      {who === 'groom' ? (
        <ul>
          <li>
            <a href="tel:01047828569">부 강다영 010-3782-8569</a>
          </li>
          <li>
            <a href="tel:01054738569">모 김정순 010-5473-8569</a>
          </li>
          <li>
            <a href="tel:01043622022">신랑 강슬기 010-4362-2022</a>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <a href="tel:01091469411">부 김남춘 010-9146-9411</a>
          </li>
          <li>
            <a href="tel:01062439417">모 이경순 010-6243-9417</a>
          </li>
          <li>
            <a href="tel:01047829416">신부 김진주 010-4782-9416</a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default function Contact() {
  const modal = useModal();

  const handleContact = (who) => {
    modal.show({
      title: who === 'groom' ? '신랑측' : '신부측',
      content: <TelModal who={who} />,
    });
  };
  return (
    <div className="contact">
      <button className="groom" type="button" onClick={handleContact.bind(null, 'groom')}>
        <span>신랑</span>
      </button>
      <div className="seperator" />
      <button className="bride" type="button" onClick={handleContact.bind(null, 'bride')}>
        <span>신부</span>
      </button>
    </div>
  );
}
