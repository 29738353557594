import React from 'react';
import LottieView, { LottieViewProps } from './LottieView';
import FlowerCircleJSON from '../lottie/FlowerCircle.json';

export const FlowerCircleProps = {
  ...LottieViewProps,
  children: undefined,
  contentContainerStyle: {},
};
export default function FlowerCircle(props = FlowerCircleProps) {
  const { contentContainerStyle, children, delay, ...lottieProps } = Object.assign({}, FlowerCircleProps, props);
  const [finishDelay, setFinishDelay] = React.useState(false);
  React.useEffect(() => {
    if (!delay || delay < 0) {
      setFinishDelay(true);
      return;
    }
    const t = setTimeout(() => {
      setFinishDelay(true);
    }, delay);
  }, []);
  // if (!finishDelay) return null;
  return (
    <div className="flower-circle">
      {finishDelay && <LottieView {...lottieProps} className="inner" animationData={FlowerCircleJSON} speed={0.4} />}
      <LottieView {...lottieProps} className="inner-hide" animationData={FlowerCircleJSON} speed={0.4} />
      {children && (
        <div className="content-container" style={contentContainerStyle}>
          {children}
        </div>
      )}
    </div>
  );
}
