import React from 'react';
import cookie from 'react-cookies';
import kakao from '../commons/kakao';
const KakaoLoginContext = React.createContext({
  login: async () => {},
  logout: async () => {},
  info: {
    connected_at: '',
    id: 0,
    kakao_account: {},
    properties: {
      nickname: '',
      profile_image: '',
      thumbnail_image: '',
    },
  },
  isLogin: false,
});

export const useKakaoLogin = () => {
  return React.useContext(KakaoLoginContext);
};

export default function KakaoLoginProvider({ children }) {
  const [auth, setAuth] = React.useState();
  const [info, setInfo] = React.useState();
  const handleAuth = (auth) => {
    setAuth(auth);
    kakao.Auth.setAccessToken(auth.access_token);
    return new Promise((r, j) =>
      kakao.API.request({
        url: '/v2/user/me',
        success: (info) => {
          r(info);
          setInfo(info);
        },
        fail: j,
      }),
    );
  };
  // auto login
  React.useEffect(() => {
    const auth = cookie.load('auth');
    if (auth) {
      console.log('auth check', auth);
      handleAuth(auth);
    }
  }, []);
  const value = React.useMemo(
    () => ({
      login: () => {
        return new Promise((r, j) =>
          kakao?.Auth.login({
            success: async (auth) => {
              cookie.save('auth', auth);
              handleAuth(auth);
              r(auth);
            },
            fail: (err) => {
              // console.log(JSON.stringify(err));
              j(err);
            },
          }),
        );
      },
      logout: () => {
        return new Promise((r, j) =>
          kakao.API.request({
            url: '/v1/user/unlink',
            success: function (response) {
              kakao.Auth.logout(() => {
                setAuth(null);
                setInfo(null);
                r();
              });
            },
            fail: function (error) {
              // console.log(error);
            },
          }),
        );
      },
      info,
      isLogin: !!info,
    }),
    [auth, info],
  );
  return <KakaoLoginContext.Provider value={value}>{children}</KakaoLoginContext.Provider>;
}
