import React from 'react';
import Ime from 'korean-ime-simple';
import { joinArray } from '../commons/utils';
import { destructiveKorean } from '../commons/destructiveKorean';

const SpeachTextDefaultProps = {
  className: undefined,
  autoStart: true,
  delay: 0,
  speed: 50,
  children: '',
  didFinish: () => {},
  nl2br: true,
};

function Speech(props = SpeachTextDefaultProps, ref) {
  const { className, autoStart, delay, speed, children, didFinish, nl2br } = Object.assign(
    {},
    SpeachTextDefaultProps,
    props,
  );
  const controller = React.useRef({
    acc: '',
    origin: destructiveKorean(children),
    cursor: 0,
    isPending: false,
  }).current;
  const [text, setText] = React.useState('');
  const timeoutRef = React.useRef();

  if (ref) {
    ref.current = {
      reset: () => {
        controller.acc = '';
        controller.cursor = 0;
        setText('');
        clearTimeout(timeoutRef);
      },
      start: () => {
        typeText();
      },
    };
  }

  const typeText = () => {
    if (controller.cursor >= controller.origin?.length) {
      didFinish?.();
      return;
    }

    const element = controller.origin[controller.cursor];
    // console.log('setText', { prev: p, element, cursor: controller.cursor });
    controller.cursor++;
    if (typeof element !== 'undefined') {
      controller.acc = Ime(controller.acc, element);
      // console.log({ text, element, result: controller.acc });
      setText(controller.acc);
    }

    timeoutRef.current = setTimeout(() => {
      window.requestAnimationFrame(typeText);
      // typeText();
      clearTimeout(timeoutRef.current);
    }, speed);
  };

  // start
  React.useEffect(() => {
    if (!autoStart) return;
    if (controller.isPending) return;
    controller.isPending = true;

    console.log('autostart', children, controller);

    const t = setTimeout(() => {
      typeText();
      clearTimeout(t);
    }, delay);
  }, [delay, autoStart]);

  // update props
  // React.useEffect(() => {
  //   controller.origin = destructiveKorean(children);
  //   controller.cursor = 0;
  // }, [children]);
  return (
    <div className="speach-container">
      <p className={`${className} speach-main`}>
        {nl2br ? joinArray(text?.split(/\n/g), (i) => <br key={`br-${i}`} />) : text}
      </p>
      <p className={`${className} hidden`}>
        {nl2br ? joinArray(children?.split(/\n/g), (i) => <br key={`br-${i}`} />) : children}
      </p>
    </div>
  );
}

Speech.defaultProps = SpeachTextDefaultProps;

export default React.forwardRef(Speech);
