import React from 'react';

const useScreen = () => {
  const [{ width, height, initWidth, initHeight }, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
    initWidth: window.innerWidth,
    initHeight: window.innerHeight,
  });
  const isMobile = React.useMemo(() => width <= 480, [width]);
  React.useEffect(() => {
    const handleSize = (e) => {
      const { innerWidth, innerHeight } = window ?? {};
      // console.log(`onResize ${innerWidth} ${innerHeight}`, e);
      if (innerWidth != width || innerHeight != height)
        setSize((p) => ({ ...p, width: innerWidth, height: innerHeight }));
    };
    window.addEventListener('resize', handleSize);
    handleSize();
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  return { width, height, initWidth, initHeight, isMobile };
};

export default useScreen;
