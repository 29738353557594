import React from 'react';
import kakao from '../commons/kakao';
import { useKakaoLogin } from '../contexts/KakaoLogin';

export default function KakaoLogin() {
  const kakaoLogin = useKakaoLogin();
  const handleLogin = (e) => {
    e.preventDefault();
    kakaoLogin.login();
  };
  React.useEffect(() => {}, [kakaoLogin.isLogin]);

  if (kakaoLogin.isLogin) {
    return null;
  }

  return (
    <a id="custom-login-btn" href="#" onClick={handleLogin}>
      <img src="//k.kakaocdn.net/14/dn/btroDszwNrM/I6efHub1SN5KCJqLm1Ovx1/o.jpg" width="222" alt="카카오 로그인 버튼" />
    </a>
  );
}
