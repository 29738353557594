import React from 'react';
import kakao from '../commons/kakao';

export default function Share() {
  const handleShare = () => {
    kakao.Link.sendDefault({
      objectType: 'text',
      text: 'haha',
      link: {
        mobileWebUrl: 'https://jjsk.wedding',
        webUrl: 'https://jjsk.wedding',
      },
    });
  };
  // React.useEffect(() => {

  //   kakao?.Link?.createScrapButton({
  //     container: '#create-kakao-link-btn',
  //     requestUrl: 'https://jjsk.wedding',
  //   });
  // }, []);

  return (
    <div className="share">
      <a href="#" onClick={handleShare}>
        <img
          src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
          alt="카카오링크 보내기 버튼"
        />
        <span>카카오톡으로 공유하기</span>
      </a>
    </div>
  );
}
