import React from 'react';
import * as Database from 'firebase/database';
import { useKakaoLogin } from '../contexts/KakaoLogin';
import KakaoLogin from './KakaoLogin';

export default function Attend() {
  const login = useKakaoLogin();
  console.log(login);
  const [vote, setVote] = React.useState();

  React.useEffect(() => {
    if (!login?.info?.id) return;

    const db = Database.getDatabase();
    const ref = Database.ref(db, `attends/${login.info.id}`);
    const unsubscribeOn = Database.onValue(ref, (snapshot) => {
      setVote(snapshot.val()?.vote);
    });
    Database.get(ref).then((snapshot) => {
      setVote(snapshot.val()?.vote);
    });
    return () => {
      unsubscribeOn();
    };
  }, [login?.info]);

  const handleAttend = async (vote) => {
    setVote(vote);
    const db = Database.getDatabase();
    const ref = Database.ref(db, `attends/${login.info.id}`);
    await Database.set(ref, {
      nickname: login.info.properties.nickname,
      date: Date.now(),
      vote,
    });
  };

  return (
    <div className="attend">
      <span>
        참석 여부가 확정 되시면, <br />
        아래의 참석 여부를 선택 해주세요.
        <br />
        <br />
        참석 여부 선택은 피로연 준비에 도움이 됩니다.{' '}
      </span>
      {!login.isLogin ? (
        <KakaoLogin />
      ) : (
        <div>
          <button
            className={`attend-confirm ${vote === 'attend' ? 'selected' : ''}`}
            onClick={handleAttend.bind(null, 'attend')}
          >
            참석
          </button>
          <button
            className={`attend-unknown ${vote === 'unknown' ? 'selected' : ''}`}
            onClick={handleAttend.bind(null, 'unknown')}
          >
            미정
          </button>
        </div>
      )}
    </div>
  );
  k;
}
