import React from 'react';
import * as Database from 'firebase/database';
import { formatTimeAgo } from '../commons/utils';
import useScreen from '../hooks/useScreen';
import { calcCommentData, useComment } from '../contexts/CommentContext';
import Loading from './Loading';
import { useKakaoLogin } from '../contexts/KakaoLogin';
import { ALLOW_REPLY_KAKAO_ID } from '../commons/defines';

const CommentProps = {
  disableReply: false,
  comment: {
    who: '',
    message: '',
    date: '',
  },
  depth: 1,
};

export function Comment(props = CommentProps) {
  const { comment, disableReply, depth } = Object.assign({}, CommentProps, props);
  const { setReplyTarget } = useComment();
  const handleReply = () => {
    setReplyTarget(comment);
  };
  const { info } = useKakaoLogin();
  const canReply =
    comment?.who?.id && info?.id && (ALLOW_REPLY_KAKAO_ID.includes(info?.id) || comment?.who?.id === info?.id);
  const reply = React.useMemo(() => {
    return calcCommentData(comment?.reply);
  }, [comment.reply]);
  return (
    <>
      <div className={`comment${depth > 1 ? ' reply' : ''}`}>
        <div className="comment-auth">
          {comment.who?.thumbnail_image && <img className="profile" src={comment.who?.thumbnail_image} alt="" />}
        </div>
        <div className="message-container">
          <span className="who">
            {comment.who?.nickname}
          </span>
          <span className="message">{comment?.message}</span>
          <span className="date">{formatTimeAgo(comment?.date)}</span>
        </div>
        {canReply && !disableReply && (
          <button type="button" onClick={handleReply}>
            답글
          </button>
        )}
      </div>
      {reply?.length > 0 &&
        reply.map((comment) => {
          return <Comment comment={comment} key={comment.id} disableReply depth={depth + 1} />;
        })}
    </>
  );
}

export default function Commenets() {
  const ref = React.useRef();
  const [listCount, setListCount] = React.useState(5);
  const [comments, setComments] = React.useState([]);

  React.useEffect(() => {
    const db = Database.getDatabase();
    const ref = Database.ref(db, 'comments');
    const unsubscribeOn = Database.onValue(ref, (snapshot) => {
      setComments(calcCommentData(snapshot.val()));
    });
    Database.get(ref).then((snapshot) => {
      setComments(calcCommentData(snapshot.val()));
    });

    return () => {
      unsubscribeOn();
    };
  }, []);

  const { height } = useScreen();
  const { inputShow, setInputShow } = useComment();
  React.useEffect(() => {
    const handleScroll = () => {
      const { y } = ref.current.getBoundingClientRect();
      if (y - height < 0 && !inputShow) {
        setInputShow(true);
      }
      if (y - height > 0 && inputShow) {
        setInputShow(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inputShow]);

  return (
    <div className="comments-container" ref={ref}>
      {!comments ? (
        <Loading />
      ) : comments.length === 0 ? (
        <span>
          축하 메세지가 없네요. <br /> 축하해주세요 ㅎㅎ
        </span>
      ) : (
        <div className="comments">
          {comments
            .filter((_, i) => i < listCount)
            .map((comment) => {
              return <Comment comment={comment} key={comment.id} />;
            })}
        </div>
      )}

      {comments?.length > listCount && (
        <button type="button" className="more" onClick={() => setListCount((p) => p + 5)}>
          더보기 + ({comments?.length - listCount})
        </button>
      )}
    </div>
  );
}
