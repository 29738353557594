import { initializeApp } from "firebase/app";
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebaseInstance/storage';

const app = initializeApp({
  apiKey: "AIzaSyDCKAmfgi0El2rTUzvh2oLvj4Y76YAVZSs",
  authDomain: "jjsk-wedding-invitation.firebaseapp.com",
  databaseURL: "https://jjsk-wedding-invitation-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "jjsk-wedding-invitation",
  storageBucket: "jjsk-wedding-invitation.appspot.com",
  messagingSenderId: "34956228623",
  appId: "1:34956228623:web:582810522ce9721f8b8b7a",
  measurementId: "G-CQYEH8LDNF"
});
export default app;