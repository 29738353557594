import React from 'react';
import useScreen from '../hooks/useScreen';

// 37.4819407,126.7984955

const { kakao } = window;
export function initMap(lat, lng) {
  const container = document.querySelector('.map');
  const options = {
    center: new kakao.maps.LatLng(lat, lng),
    level: 5,
  };
  const map = new kakao.maps.Map(container, options);
  const title = 'MJ 컨벤션';
  var marker = new kakao.maps.Marker({
    tooltip: title,
    title,
    map: map,
    // draggable:true,
    position: new kakao.maps.LatLng(lat, lng),
    clickable: true,
  });
  global.map = map;
  return map;
}

export const initRoadView = (lat, lng, degree) => {
  const roadviewContainer = document.querySelector('.road'); //로드뷰를 표시할 div
  const roadview = new kakao.maps.Roadview(roadviewContainer); //로드뷰 객체
  const client = new kakao.maps.RoadviewClient(); //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체

  const position = new kakao.maps.LatLng(lat, lng);

  // 특정 위치의 좌표와 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄운다.
  client.getNearestPanoId(position, 50, function (panoId) {
    roadview.setPanoId(panoId, position); //panoId와 중심좌표를 통해 로드뷰 실행

    setTimeout(() => {
      roadview.setViewpoint({
        pan: 184.4880142488123,
        tilt: -6.991293583217481,
        zoom: -3,
      });
    }, 1000);
  });

  global.roadview = roadview;

  return {
    roadview,
    client,
  };
};

const Map = () => {
  const controllers = React.useRef({
    map: undefined,
    road: undefined,
  }).current;
  React.useEffect(() => {
    const lat = 37.4819407;
    const lng = 126.7984955;
    controllers.map = initMap(lat, lng);
    controllers.map.setDraggable(false);
    controllers.map.setZoomable(false);

    controllers.road = initRoadView(lat, lng, 50);
  }, []);

  const { width } = useScreen();

  return (
    <>
      <div className="map-container">
        <div className="map-overlay" onClick={(e) => e?.preventDefault()} />

        <div className="map" />
        <div className="road" />
      </div>
      <div className="traffic">
        <b>지하철 이용시</b>
        <ul>
          <li>1호선 소사역 1번출구 도보 5분</li>
        </ul>
        <b>버스 이용시</b>
        <ul>
          <li>부천 축협 앞 : 83, 88, 88-1, 96, 19</li>
          <li>소사어울마당 삼거리 : 6-1, 53, 96</li>
          <li>소사역•소사지구대 : 6-1, 63, 88-1 마을(녹색교통차고지 시흥고등학교)</li>
          <li>소사시장•소사푸르지오 : 56, 56-1, 60-1</li>
          <li>오정동 : 71, 95</li>
        </ul>
        {/* <b>자가용 이용시</b>

        <div className="navigate">
          <button className="" />
          <button className="" />
          <button className="" />
          <button className="" />
        </div> */}
        <b>주차장 안내</b>
        <table>
          <thead>
            <tr>
              <th>주차타워</th>
              <th>본관</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>8층</td>
              <td>5층 그랜드볼룸 / 파티오볼룸 예식홀</td>
            </tr>
            <tr>
              <td>6층</td>
              <td>4층 연회장 / 계산실 / 웨딩홀 연결통로</td>
            </tr>
            <tr>
              <td>4층</td>
              <td>3층 3층 다이너스티홀 / 연회장</td>
            </tr>
            <tr>
              <td>3층</td>
              <td>2층 웨딩홀 연결통로 본관 엘리베이터 이용</td>
            </tr>
            <tr>
              <td>1층</td>
              <td>1층 본관 엘리베이터</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Map;
