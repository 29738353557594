import React from 'react';
import './App.scss';
import app from './commons/firebase';
import * as Database from 'firebase/database';
import PhotoCarousel from './components/PhotoCarousel';
import Map from './components/Map';
import Speech from './components/Speech';
import Commenets from './components/Comments';
import Share from './components/Share';
import Section from './components/Section';
import { useModal } from './contexts/ModalContext';
import FlowerCircle from './components/FlowerCircle';
import Contact from './components/Contact';
import PhotoGrid from './components/PhotoGrid';
import useScreen from './hooks/useScreen';
import ScrollReveal from './components/ScrollReveal';
import CommentInput from './components/CommentInput';
import CommentProvider from './contexts/CommentContext';
import Attend from './components/Attend';
import ClipboardJS from 'clipboard';

const revealOptions = {
  reset: false,
  origin: 'bottom',
  opacity: 0,
  delay: 0,
  duration: 1000,
  distance: '100px',
};

function App() {
  React.useEffect(() => {
    new ClipboardJS('.copy');
    (async () => {
      const db = Database.getDatabase();
      // console.log(app, db);
      const ref = Database.ref(db, 'haha');
      Database.onValue(ref, (snapshot) => {
        // console.log('Database.onValue', snapshot, snapshot.val());
      });

      await Database.set(ref, 'huhuhu');
      const value = await Database.get(ref);
      // console.log({ app, database: db, ref, value });
    })();
  }, []);

  const mainSpeachRef = React.useRef();
  const { initHeight } = useScreen();

  return (
    <div>
      {/* // todo : artwork */}
      <div className="bg" />
      <ScrollReveal
        options={{
          ...revealOptions,
          reset: true,
          origin: 'top',
        }}
      >
        <div className={'main'} style={{ height: initHeight }}>
          <h1>
            <Speech delay={1000} speed={100}>
              {'  We  \n  Getting  \n  Married!!!  '}
            </Speech>
          </h1>
          <FlowerCircle delay={3000} contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}>
            <Speech className="subject" delay={3000 + 1000} speed={80}>
              {'슬기 & 진주'}
            </Speech>
          </FlowerCircle>
          <div style={{ height: 120 }}>
            <Speech className="date-place" delay={6000} speed={80}>
              2022.03.12 • 토 • 오전 • 11시
            </Speech>
            <Speech className="date-place" delay={9000} speed={80}>
              부천 MJ 컨벤션 • 3층 다이너스티홀
            </Speech>
          </div>
        </div>
      </ScrollReveal>

      <ScrollReveal
        options={revealOptions}
        onBeforeReveal={() => {
          mainSpeachRef.current?.reset();
        }}
        onAfterReveal={() => {
          mainSpeachRef.current?.start();
        }}
      >
        <Section header={'모시는 글'}>
          <Speech
            ref={mainSpeachRef}
            className={'speach'}
            delay={500}
            speed={30}
            autoStart={false}
            didFinish={() => {
              // console.log('haha');
            }}
            nl2br
          >
            {`모두가 어렵고 힘든 시기에
            결혼 소식을 전하게 되어
            죄송스러운 마음이 큽니다.
            
            작은 인연으로 만나,
            큰 사랑을 키우게 되어,
            행복한 가정을 이루고자 합니다.
            
            참석이 어려우시더라도
            계신 자리에서 축복해 주시면
            그 마음 평생 간직하고 행복하게 살겠습니다.
            
            강다영 • 김정순 의 장남 슬기
            김남춘 • 이경순 의 장녀 진주

            `}
          </Speech>
        </Section>
      </ScrollReveal>

      <ScrollReveal options={revealOptions}>
        <Section header={'연락하기'}>
          <Contact />
        </Section>
      </ScrollReveal>

      <ScrollReveal options={revealOptions}>
        <PhotoGrid />
      </ScrollReveal>

      <ScrollReveal options={revealOptions}>
        <Section header={'오시는길'}>
          <Map />
        </Section>
      </ScrollReveal>

      <ScrollReveal options={revealOptions}>
        <Section header={'참석 여부 선택'}>
          <Attend />
        </Section>
      </ScrollReveal>

      <ScrollReveal options={revealOptions}>
        <Section header="마음 전하실 곳" bodyClassName={'send'}>
          <span>신랑</span>
          <button className="copy broom" data-clipboard-text="강다영 국민은행 671401-01-366450">
            강다영 국민 671401-01-366450
          </button>
          <button className="copy broom" data-clipboard-text="강슬기 기업은행 202-045092-02-017">
            강슬기 기업 202-045092-02-017
          </button>
          <span>신부</span>
          <button className="copy bride" data-clipboard-text="김진주 농협은행 216028-51-169043">
            김진주 농협 216028-51-169043
          </button>
        </Section>
      </ScrollReveal>

      <ScrollReveal options={revealOptions}>
        <Section header="공유하기">
          <Share />
        </Section>
      </ScrollReveal>

      <CommentProvider>
        <ScrollReveal options={revealOptions}>
          <Section header={'축하해주세요.'}>
            <Commenets />
          </Section>
        </ScrollReveal>

        <CommentInput placeholder="축하 메세지를 남겨주세요." />
      </CommentProvider>

      {/* <KakaoLogin /> */}
    </div>
  );
}

export default App;
